var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"files container-fluid align-center px-6 pt-6 pb-0"},[_c('v-row',{attrs:{"gutters":3,"pa-2":""}},[_c('v-col',{staticStyle:{"max-width":"300px !important"}},[_c('div',{staticClass:"sidebar"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Files')))]),_c('h4',[_vm._v(_vm._s(_vm.$t('Projects')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.project),callback:function ($$v) {_vm.$set(_vm.filters, "project", $$v)},expression:"filters.project"}},[_vm._l((_vm.projects),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.project_name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1),_c('h4',[_vm._v(_vm._s(_vm.$t('Categories')))]),(_vm.file_categories)?_c('div',[_c('v-treeview',{attrs:{"selectable":"","item-disabled":"locked","items":_vm.groupCategories(
                        _vm.file_categories,
                        'parent_id',
                        'name',
                        'id'
                      )},model:{value:(_vm.filters.categoryId),callback:function ($$v) {_vm.$set(_vm.filters, "categoryId", $$v)},expression:"filters.categoryId"}})],1):_vm._e()],1)]),_c('v-col',[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"search-box"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filters.keyword),callback:function ($$v) {_vm.$set(_vm.filters, "keyword", $$v)},expression:"filters.keyword"}})],1)]),_c('v-data-table',{staticClass:"elevation-1 file-table",attrs:{"headers":_vm.headers,"items":_vm.files,"page":_vm.filters.page,"items-per-page":_vm.filters.rowsPerPage,"sort-by":_vm.filters.sort.name,"sort-desc":_vm.filters.sort.desc,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [25,50,100]
          }},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"item.record_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.record_id)+" ")]}},{key:"item.document_name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"document_name"},[(item.document_file.show_format=='img')?[_c('v-img',{staticClass:"mr-3",attrs:{"src":'https://beta.cloud.kiva.app/support/client/attachment?documentId=' +
                          item.document_file.record_id +
                          '&fileId=' +
                          item.document_file.uid,"max-height":"20","max-width":"30"}},[_c('v-card-title',{domProps:{"textContent":_vm._s(item.document_file.title)}})],1)]:[_c('i',{class:_vm.getFileIcon(item.document_file, ' fad mr-3 ', 'md')})],_vm._v(" "+_vm._s(item.document_name)+" ")],2)]}},{key:"item.rel_created_by_label",fn:function(ref){
                          var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.rel_created_by_label)),_c('br'),_c('small',[_vm._v(_vm._s(item.created_on))])])]}},{key:"item.document_type",fn:function(ref){
                          var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.document_file.type)+" ")])]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [_c('div',[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.openFileUrl(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }