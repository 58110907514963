import { BaseProxy } from "@/proxies/BaseProxy";

export class FileProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `files`,
      parameters,
    });
  }
}


// /files -> dm/document modülüden liste veri çekecek (projectId ve categoryId filtrelerini alabilecek)
// /pages -> pages/page modülünden liste veri çekecek (projectId ve categoryId filtrelerini alabilecek)
// /page -> pages/page modülünden bir kayıdın detayını döndürecek (pageId parametre olarak alacak)