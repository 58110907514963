<template>
  <section class="files container-fluid align-center px-6 pt-6 pb-0">
    <!-- <div class="pb-4 text-right">
      <v-btn color="primary" :to="{ name: 'createProject' }" depressed large
        >Upload File
      </v-btn>
    </div> -->

    <v-row  :gutters="3" pa-2>
      <v-col style="max-width: 300px !important">
        <div class="sidebar">
          <h3>{{$t('Files') }}</h3>
          <!-- <v-text-field
            label="Search"
            outlined
            dense
            filled
            v-model="search_keyword"
            append-icon="mdi-search"
          ></v-text-field> -->
          <h4>{{$t('Projects') }}</h4>
          <v-list>
            <v-list-item-group v-model="filters.project" multiple>
              <template v-for="(item, i) in projects">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.project_name"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template> </v-list-item-group
          ></v-list>
          <h4>{{$t('Categories') }}</h4> 
              <div v-if="file_categories">
                    <v-treeview
                      v-model="filters.categoryId"
                      selectable
                      item-disabled="locked"
                      :items="groupCategories(
                          file_categories,
                          'parent_id',
                          'name',
                          'id'
                        )"
                    ></v-treeview> 
              </div>
        </div>
      </v-col>
      <v-col>

           <v-card>
          <v-card-title>

        <div class="search-box">
          <v-text-field
            v-model="filters.keyword"
            append-icon="mdi-magnify"
            :label="$t('Search')"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </div>
          </v-card-title>
        <v-data-table
          :headers="headers"
          :items="files"
          class="elevation-1 file-table"
          :page.sync="filters.page"
          :items-per-page="filters.rowsPerPage"
          :sort-by.sync="filters.sort.name"
          :sort-desc.sync="filters.sort.desc"
          :server-items-length="total"
          :loading="loading"
           :footer-props="{
              'items-per-page-options': [25,50,100]
            }"
        >
          <template v-slot:item.record_id="{ item }">
            {{ item.record_id }}
          </template>
          <template v-slot:item.document_name="{ item }">
            <span class="document_name">
              <!-- <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="20"
                max-width="30"
                  v-if="isImageType(item.document_file)"
                src="https://picsum.photos/id/11/500/300"
              ></v-img> -->



                <template v-if="item.document_file.show_format=='img'">
                        <v-img
                          :src="
                            'https://beta.cloud.kiva.app/support/client/attachment?documentId=' +
                            item.document_file.record_id +
                            '&fileId=' +
                            item.document_file.uid
                          "
                             max-height="20"
                             max-width="30"
                             class="mr-3"
                        >
                          <v-card-title v-text="item.document_file.title"></v-card-title>

                        </v-img>
                      </template>
                      <template v-else>
                          <i
                            :class="getFileIcon(item.document_file, ' fad mr-3 ', 'md')"
                          ></i>
                      </template>


              {{ item.document_name }}
            </span>
          </template>
          <template v-slot:item.rel_created_by_label="{ item }">
            <div>
              {{ item.rel_created_by_label }}<br />
              <small>{{ item.created_on }}</small>
            </div>
          </template>
          <template v-slot:item.document_type="{ item }">
            <div>
              {{ item.document_file.type }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <a @click="openFileUrl(item)" target="_blank">
                <v-icon>mdi-download</v-icon>
              </a>
            </div>
          </template>
        </v-data-table>

           </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { FileProxy } from "@/proxies/FileProxy";
import { ProjectProxy } from "@/proxies/ProjectProxy";
import { FileCategories } from "@/proxies/FileCategories";
import fileMixin from "@/mixins/files";

export default {
  name: "File",
  mixins:[fileMixin],
  data() {
    return {
      projectSelected: [],
      model: [],
      total: 0,

      headers: [
        {
          text: "#",
          align: "end",
          value: "record_id",
        },
        {
          text: this.$t('Document Name'),
          align: "start",
          value: "document_name",
        },

        { text: this.$t('Type'), value: "document_type" },
        { text: this.$t('Category'), value: "rel_category_label" },
        // { text: "Project", value: "rel_created_by_label" },
        { text: this.$t('Created by'), value: "rel_created_by_label" },
        { text: this.$t('Action'), value: "action" },
      ],
      files: [],
      projects: [],
      url: "https://beta.cloud.kiva.app/kiva/jsapi/bridge/data/dm/document",
      file_categories: [],
      search_keyword: "",
      loading: false,
      filters: {
        page: 1,
        keyword: "",
        rowsPerPage: 50,
        categoryId: [],
        project: [],
        sort: {
          name: ["record_id"],
          desc: [false],
        },
      },
    };
  },
  watch: {
    model() {
      this.getFiles();
    },

    filters: {
      immediate: true,
      deep: true,
      handler: "getFiles",
    },
  },
  created() {
    this.getProjects();
    this.getFileCategories();
  },
  methods: {

    getFileUrl(att) {

      let file = att.document_file;
      if (!file.file_id && file.id) {
        file.file_id = file.id;
      }
      let nm = file.name.split(".");
      let t = nm.length - 1;
      if (nm[t]) {
        file.file_type = nm[t];
      }

      return `${this.url}/${att.record_id}/document/${att.document_file.id}`;
    },
    openFileUrl(att) { 
      let file = att.document_file;
      if (!file.file_id && file.id) {
        file.file_id = file.id;
      }
      let nm = file.name.split(".");
      let t = nm.length - 1;
      if (nm[t]) {
        file.file_type = nm[t];
      }

      let r = `${this.url}/${att.record_id}/document/${file.id}?fn=${att.document_name}.${file.file_type}`;

      // let r = `${this.url}?app=` + app + `&model=` + model + `&record_id=` + record_id + '&file_name=' + att.name + '&file_type=' + att.file_type + '&uid=' + att.file_id + '&_blank=1&_csrf=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOjI5MzI0LCJleHAiOjE1ODQ1MDA4Mjl9.Hb-ptWmjnoDhg7sGkXa2PDjJQ-K0UpoU6fjVEi7MAh4'
      window.open(r);
    },


    getFiles() {
      this.loading = true;
      let parameters = { ...this.filters };

      parameters.projectId = this.filters.project.map((k) => {
        return k.record_id;
      }); 
      parameters.categoryId = this.filters.categoryId
      // console.log("parameters", parameters, this.filters);
      new FileProxy()
        .setParameters(parameters)
        .all()
        .then((response) => {
          let files = response.data;
          this.files = files.map((a) => {
            a.document_file = JSON.parse(a.document_file);
            a.isImage = this.isImageType(a.document_file);
            return a;
          });
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    groupCategories(cats, rel_field, name_field, id_field) {
      console.log("cats",cats,rel_field)
      let res = [];
      cats.forEach((k) => {
        res.push({
          ...k,
          locked: false,
          children: [],
        });
      });
 

      res.forEach((k) => {
        if (k[rel_field]) {
          let found = res.find((a) => a.record_id == k[rel_field]);
          found.children.push(k);
        }
      });

      let ct = [];
      res.forEach((k) => {
        if (k[rel_field] === null) {
          ct.push(k);
        }
      });
      return ct;
    },

    getFileCategories() {
      // .update({id,item:{}})
      new FileCategories().all().then((response) => {
          let res = [];
        let fc = response.data;
 
           this.file_categories = fc.map((k) => {
          return {
            id: k.category_3_id ? k.category_3_id : k.category_2_id ? k.category_2_id  : k.category_1_id ? k.category_1_id : null,
            name:  ( k.category_3_name ? k.category_3_name : k.category_2_name ? k.category_2_name  : k.category_1_name ? k.category_1_name : '' ) + '  '+ '('+k.filesCount+')',
            parent_id: k.category_3_id ? k.category_2_id : k.category_2_id ? k.category_1_id  :   null,
            parent_name:  k.category_3_name ? k.category_2_name : k.category_2_name ? k.category_1_name  : null,
     
          };
        });  
 
      });
    },
    getProjects() {
      // .update({id,item:{}})
      new ProjectProxy().all().then((response) => {
        this.projects = response.data;
      });
    },
    
  },
};
</script>


<style lang="scss" scoped>
.sidebar {
  background: #ebeef2;
  max-width: 500px;
  height: calc(100vh - 110px);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
width: 270px;

  h3 {
    color: #465669;
    font-size: 18px;
    font-weight: 600;
    line-height: 3;
  }

  h4 {
    color: #465669;
    font-size: 14px;
    font-weight: 600;

    line-height: 2;
  }
  .v-list.theme--light {
    background: none;
  }

  .v-list {
    max-height: 250px;
    overflow: auto;
  }
  .v-list-item {
    flex-direction: row-reverse;
    height: 30px;
    min-height: 30px;
    padding: 0px;
  }

  .v-list-item__action {
    margin-left: 0 !important;
    margin: 2px 16px 2px 0px;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 0.8rem;
    color: #465669;
  }
}
</style>
<style>
.icon-file{
  font-size: 120px;

}
.v-responsive__content{
  text-align: center;
}
.v-text-field--outlined fieldset {
  background: #fff !important;
}
.document_name .v-image{
  margin-right: 10px;
}
.document_name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center
}
.search-box {
  padding: 10px 0;
}

 

.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 35px;
  padding-left: 0px;
  padding-right: 3px;
  position: relative;
  font-size: 12px;
  margin-left: -10px;
}

.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 0px;
}
</style>
